import React from "react";
import Logo from "../logooseber/LogoFinal.png";
import 'bootstrap/dist/css/bootstrap.min.css';

const Mantencion = () => {
  const whatsappNumber = "+56935610032"; // Reemplaza con tu número de WhatsApp
  const whatsappMessage = "Hola, estoy interesado en más información."; // Mensaje predeterminado

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-white text-dark text-center">
      <img src={Logo} alt="Logo Oseber" className="mb-4" style={{ width: "500px", height: "auto" }} />
      <h1 className="mb-3 display-4">Estamos en Mantenimiento</h1>
      <p className="lead">Volveremos pronto. Mientras nos puedes contactar por WhatsApp.</p>
      <div className="mt-4">
        <a
          href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`}
          className="btn btn-success btn-lg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i> Contactar por WhatsApp
        </a>
      </div>
    </div>
  );
};

export default Mantencion;